@import '../_import.scss';


@mixin footer-link-style($underline-color) {
	font-size: 1rem;
	line-height: 1;
	color: var(--theme-white);
	text-decoration-color: rgba($underline-color, 0.5);

	&:hover {
		color: $underline-color;
		text-decoration-color: currentColor;
	}
}

.footer {
	background: var(--theme-true-black);
	width: 100%;
	padding: 7.5% 2rem 3rem 2rem;
	display: flex;
	flex-flow: row nowrap;
	position: relative;

	a {
		color: var(--theme-white);
		text-decoration-color: var(--theme-dark-grey);

		&:hover {
			text-decoration-color: var(--theme-dark-red);
		}

		&:focus {
			@include focusDarkBg;
		}
	}

	.swoosh {
		width: 100%;
		position: absolute;
		top: -0.125rem;
		left: 0;
		right: 0;

		path {
			fill: var(--footer-fill);
		}
	}


	&, h2, p {
		color: var(--light-text);
		background: var(--theme-true-black);
	}

	.footer-grid {
		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: 1fr min-content min-content;
		grid-template-areas: "main fare links";
		width: 100%;
		max-width: $main-content-width;
		margin: 0 auto;
		padding: 4rem 0 0 0;
		margin-top: -2rem;

		.main {
			margin-top: -6rem;
			grid-area: main;

			@media only screen and (max-width :$footer-move-logo-down) {
				margin-top: -3rem;
			}
		}

		.footer-fare {
			grid-area: fare;
		}

		.footer-links {
			grid-area: links;
		}

		.footer-fare, .footer-links {
			border-left: 0.0625rem solid var(--theme-light-grey);
			padding: 0 2rem;
			min-width: 20rem;
		}

		@media only screen and (max-width: $footer-hide-fare) {
			grid-template-rows: auto auto;
			grid-template-columns: 1fr auto;
			grid-template-areas: "main links" "main fare";

			.footer-links {
				padding-bottom: 1rem;
			}

			.footer-fare {
				padding-top: 1rem;
				text-align: left;
			}
		}
	}

	.logo {
		$height: 8.5rem;
		$margin: 0.25rem;
		height: $height + $margin;
		margin: 0 0 $margin -1.125rem;

		> svg {
			height: $height;
			width: $height * 1.6;
		}
	}

	.address {

		> p,
		a {
			margin: 0;
			padding: 0;
			font-size: 1rem;
			line-height: 1.5;
		}

		.name {
			@include font-bold;
		}
	}

	.social {
		margin: 1.5rem 0 0 0;
		height: 2rem;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		a,
		button {
			flex: 0 0 auto;
			color: var(--theme-white);
			text-decoration: none;
			width: 2rem;
			height: 2rem;
			margin: 0 0 0 0.375rem;
			padding: 0;
			display: block;
			border: none;
			@include remove-ios-button-styles;
			background: none;

			> svg {
				font-size: 2rem;
				width: 2rem;
				height: 2rem;
				fill: var(--theme-white);

				&:hover {
					fill: var(--theme-dark-red);
				}
			}
		}

		span {
			flex: 0 0 auto;
			display: block;
		}
	}

	.main-footer-links {
		margin: 1rem 0 0 0;

		ul {
			display: flex;
			width: 100%;
			flex-flow: row nowrap;
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				flex: 0 0 auto;
				margin: 0 0.5rem 0 0;
				list-style: none;

				+ li:before {
					content: '|';
					margin-right: 0.5rem;
					color: var(--theme-light-grey);
					@include font-bold;
				}
			}

			@media only screen and (max-width: 472px) {
				flex-flow: column nowrap;

				> li + li:before {
					content: '';
					margin-right: 0;
				}
			}
		}
	}

	.footer-fare {
		text-align: center;
		width: 12rem;

		span {
			display: block;
		}

		.top {
			padding-bottom: 1rem;
			border-bottom: 0.0625rem dotted var(--theme-white);
		}

		.still {
			@include font-bold;
			font-size: 1.25rem;
			letter-spacing: 0.125rem;
			text-transform: uppercase;
		}

		.price {
			line-height: 5rem;

			> span {
				display: inline-block;
				vertical-align: middle;
			}

			.dollar {
				@include font-body;
				font-size: 2.5rem;
				margin-right: 0.375rem;
			}

			.number {
				@include font-display;
				font-size: 5rem;
			}
		}

		.or-less {
			font-size: 1.75rem;
			@include font-bold;
			line-height: 1;
			text-transform: uppercase;
		}

		.students {
			@include font-body;
			margin-top: 0.5rem;
			font-size: 1rem;
			line-height: 1.25;
		}

		.free {
			@include font-bold;
			line-height: 2.3125;
			font-size: 1.125rem;
			text-transform: uppercase;
		}

		.fare-link {
			> a {
				font-size: 1rem;
				color: var(--theme-white);
				background: var(--theme-true-black);
				line-height: 1;
			}
		}
	}

	.footer-links {
		> ul {
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				list-style: none;
				margin: 0 0 0.5rem 0;
			}

			strong {
				color: var(--theme-white);
				background: var(--theme-true-black);
			}
		}
	}

	.languages {

		h2 {
			@include font-bold;
			font-size: 0.75rem;
			height: 0.75rem;
			line-height: 1;
			margin: 1.375rem 0 0.5rem 0;
			letter-spacing: 0.125rem;
			text-transform: uppercase;
		}

		ul {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
		}

		li {
			@include clear-list-style();
			margin: 0.375rem 0.375rem 0 0;
		}

		a {
			@include font-bold;
			display: inline-block;
			border: 0.125rem solid var(--theme-dark-grey);
			color: var(--theme-white);
			background: var(--theme-dark-grey);
			font-size: 1.125rem;
			line-height: 1.75rem;
			border-radius: 0.5rem;
			padding: 0 0.5rem;
			text-decoration: none;

			&:hover {
				background: var(--theme-dark-red);
				border-color: var(--theme-dark-red);
			}

			&:focus {
				@include focusDarkBg;
			}
		}
	}

	@media screen and (max-width: $footer-hide-links) {
		flex-flow: column nowrap;

		.footer-grid {
			grid-template-columns: 1fr;
			grid-template-rows: auto;
			grid-template-areas: "main" "links" "fare";

			.footer-fare, .footer-links {
				border-left: none;
				padding-left: 0;
				padding-right: 0;
			}
		}

		div.main {
			width: 100%;
			margin: 0;
			padding: 0;
			border: none;
			display: flex;
			flex-flow: column nowrap;
		}
	}

	@media screen and (max-width: $footer-shrink-padding) {
		padding: 1rem;
	}
}
