@import '../_import.scss';

$width: 13rem;

a.skip-nav {
	@include font-bold;
	position: absolute;
	top: 6rem;
	left: 0;
	display: inline-block;
	padding: 0.75rem 1.5rem;
	background: var(--theme-true-black);
	z-index: 20000;
	color: var(--theme-white);
	width: $width;
	transform: translateX(-$width);
	transition: transform 0.2s ease-in-out;
	will-change: transform;

	&, &:visited {
		color: var(--theme-white);
	}

	&:focus {
		transform: translateX(0);
	}
}

div.skip-nav:focus {
	outline: none;
}
