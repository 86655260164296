@import '../_import.scss';

.form {

	$size: 1.25rem;

	.form-group {
		margin: 0 0 1rem 0;
	}

	label {
		@include default-font-family;
		@include font-bold;
		font-size: 1.25rem;
		display: block;
		width: 100%;
		padding: 0 0 0.25rem 0;
		user-select: none;

		&.required, &.required.nested > .label {
			display: block;

			&::after {
				content: '•';
				color: var(--theme-dark-red);
				margin: 0 0 0 0.125rem;
				vertical-align: super;
			}
		}

		&.required.nested::after {
			content: '';
		}
	}

	input, textarea, select {
		@include font-body;
		display: block;
		width: 100%;
		font-size: $size;
		border: 0.0625rem solid rgba(0,0,0,0.5);
		border-top: 0.1875rem solid rgba(0,0,0, 0.5);
		transition: all 0.4s ease-in-out;
		padding: 0.5rem 1rem;
		color: var(--dark-text);
		background: var(--background);

		&:focus {
			outline: none;
			border-color: rgba(0,0,0,0.75);
			border-top-color: var(--theme-dark-blue);
		}
	}

	input {
		line-height: 1;
	}

	input[type="submit"], input[type="button"] {
		-webkit-appearance: none; // safari hack
		display: inline-block;
		width: auto;
	}

	input[type="search"] {
		-webkit-appearance: none; // safari hack
	}

	.check-wrap, .radio-wrap {
		display: flex;
		flex-flow: row nowrap;

		.material-icons {
			flex: 0 0 auto;
			margin: 0 0.25rem 0 0;
		}

		label {
			flex: 1 1 auto;
			padding: 0.125rem 0;
			vertical-align: middle;
			color: var(--dark-text);
		}
	}

	.radio-wrap + .radio-wrap {
		margin: 0.25rem 0 0 0;
	}

	input[type="checkbox"], input[type="radio"] {
		@include sr-only;

		+ label {
			display: flex;
			flex-flow: row nowrap;

			> i {
				flex: 0 0 auto;
			}

			> span {
				flex: 1 1 auto;
				line-height: 1.5rem;
			}
		}

		&:focus {
			+ .checkbox, + .material-icons, + label > .material-icons {
				outline: 0.125rem solid var(--theme-dark-red);
				outline-width: 0.125rem;
			}
		}
	}

	input[type="checkbox"] {
		+ label > .material-icons::before {
			content: '\e835'; // check_box_outline_blank
		}

		&:checked + label > .material-icons::before {
			content: '\e834'; // check_box
		}
	}

	input[type="radio"] {
		+ label > .material-icons {
			margin-right: 0.375rem;

			&::before {
				content: '\e836'; // radio_button_unchecked
			}
		}

		&:checked + label > .material-icons::before {
			content: '\e837'; // radio_button_checked
		}
	}

	textarea {
		resize: none;

		&.resize-x {
			resize: horizontal;
		}

		&.resize-y {
			resize: vertical;
		}

		&.resize-x.resize-y {
			resize: both;
		}
	}

	&.dark {

		label, .material-icons {
			color: var(--theme-white);
		}

		input, textarea, select {
			border-color: transparent;
			border-top-color: rgba(0, 0, 0, 0.25);

			&:focus {
				border-top-color: var(--theme-light-blue);
			}
		}
	}

	&.validatable, &.validatable.dark {
		input, textarea, select {

			&:invalid {
				border-top-color: var(--theme-dark-red);

				&:focus {
					outline-color: var(--theme-dark-red);
				}
			}
		}
	}

	.hide-fieldset {
		border: none;
		padding: 0;

		legend {
			@include sr-only;
		}
	}

	.actions {
		margin-top: 3rem;
	}

	input[type="checkbox"], input[type="radio"] {
		&:focus {
			+ .checkbox, + .material-icons, + label > .material-icons {
				outline-color: var(--theme-light-blue);
			}
		}
	}
}
