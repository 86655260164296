@import '../_import.scss';
@import '../partials/_colors.scss';

@mixin button-state($color, $fg) {
	color: $fg;
	background: $color;
	border-color: $color;
}

@mixin hover-state($fg) {
	&:hover {
		box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	}
}

@mixin disabled-state($bg, $fg) {
	&:disabled,
	&.disabled {

		&, &:hover, &:focus {
			@include button-state($bg, $fg);
			cursor: not-allowed;
			box-shadow: none;
		}
	}
}

@mixin button($color, $fg, $disabled, $disabled-color) {
	@include hover-state($color);
	@include button-state($color, $fg);
	@include disabled-state($disabled, $disabled-color);
}

@mixin button-color($name, $color, $fg: var(--theme-white), $disabled: var(--theme-dark-grey), $disabled-color: var(--theme-white)) {
	&.#{$name} {
		@include button($color, $fg, $disabled, $disabled-color);
	}
}

input[type="submit"],
.button,
a.button {
	$small-button-height: 1.75rem;
	$button-height: 2.125rem;
	$large-button-height: 4.0625rem;
	$font-size: 1rem;
	$border-size: 0.125rem;

	@include default-font-family;
	@include remove-ios-button-styles;
	@include font-bold;
	@include button(var(--theme-dark-red), var(--theme-white), var(--theme-dark-grey), var(--theme-white));
	font-size: $font-size;
	height: $button-height;
	padding: 0 (($button-height / 2) + 0.625rem);
	border-radius: $button-height / 2;
	border-width: $border-size;
	border-style: solid;
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: center;
	text-decoration: none;
	justify-content: center;

	&:focus {
		@include focus;
	}

	.material-icons {
		font-size: 1.75rem;
		margin-right: 0.125rem;
	}

	&.block {
		display: flex;
		width: 100%;
	}

	&.small {
		height: $small-button-height;
		border-radius: $small-button-height / 2;
		padding: 0 (($small-button-height / 2) + 0.625rem);
	}

	&.large {
		@media only screen and (min-width: $button-smaller) {
			font-size: 1.5rem;
			height: $large-button-height;
			border-radius: $large-button-height /2;
			padding: 0 (($large-button-height / 2) + 0.625rem);

			.material-icons {
				font-size: 2.5rem;
				margin-right: 0.25rem;
			}
		}
	}

	&.dark-bg {
		&:focus {
			@include focus(var(--light-text));
		}
	}

	&.light-bg {
		&:focus {
			@include focus;
		}
	}
	// Other Colors
	@include button-color(blue, var(--theme-dark-blue), var(--theme-white), var(--theme-dark-grey), var(--theme-white));
	@include button-color(coral, var(--theme-dark-coral), var(--theme-white), var(--theme-dark-grey), var(--theme-white));
	@include button-color(green, var(--theme-dark-green), var(--theme-white), var(--theme-dark-grey), var(--theme-white));
	@include button-color(purple, var(--theme-dark-purple), var(--theme-white), var(--theme-dark-grey), var(--theme-white));

	&.permalink {
		&:hover,
		&:focus {
			box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
		}
	}
}
