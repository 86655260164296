@import '../_import.scss';

$hrc: var(--theme-light-grey);

hr {
	border: 0;
	height: 1px;
	background-image: linear-gradient(to right, rgba($hrc, 0), rgba($hrc, 0.75), rgba($hrc, 0));
	margin-bottom: 1.5rem;
}
