@import '../_import.scss';

$table-ui: rgba(0, 0, 0, 0.05);
$default-pad: 0.25rem;

table.table {
	border-collapse: collapse;
	color: var(--theme-black);
	table-layout: auto;
	width: 100%;

	thead {
		th {
			@include font-bold;
			font-size: 0.875rem;
			letter-spacing: 0.0625rem;
			text-align: left;
			text-transform: uppercase;
			color: var(--theme-dark-red);
			vertical-align: bottom;
			border-bottom: 0.0625rem solid $table-ui;
			padding: ($default-pad * 4) $default-pad $default-pad $default-pad;
		}
	}

	tbody {
		tr {
			&:nth-of-type(even) {
				background: $table-ui;
			}
		}

		td {
			line-height: 1rem + $default-pad;
			padding: $default-pad;
		}
	}
}
