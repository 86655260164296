@import '../_import.scss';
$dl-margin: 1rem;

dl {
	display: block;
	margin: 1rem 0;

	dt, dd {
		display: block;
	}

	dt {
		@include font-bold;
	}

	> div, > dt {
		margin: 1.5rem 0 0 0;
		display: block;

		&:first-child {
			margin-top: 0;
		}
	}

	dd {
		margin: 0;
	}

	&.colon {
		dt::after {
			content: ':';
		}
	}
}
