@import '../_import.scss';

$card-pad: 1.25rem;
$card-min-size: 25.25rem;

.card-grid {
	@include default-padding;
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: repeat(auto-fill, minmax($card-min-size, 1fr));
	width: 100%;
	grid-gap: 1.875rem;

	&.fit-cards {
		grid-template-columns: repeat(auto-fit, minmax($card-min-size, 1fr));
	}

	@media screen and (max-width: $card-min-size + ($main-content-padding-small * 2)) {
		grid-template-columns: 1fr;
	}
}

.row.striped .card-grid, .row.cards .card-grid {
	padding: 0;
}

:root {
	--card-line-clamp: 6;
}

@mixin clamp($num) {
	&.clamp-lines-#{$num} {
		--card-line-clamp: #{$num};
	}
}

.card {
	color: var(--dark-text);
	background: var(--background);
	padding: $card-pad 0;
	display: flex;
	flex-flow: column nowrap;
	border-right: none;
	border: 0.0625rem solid rgba(0, 0, 0, 0.05);
	box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0,0,0, 0.4);

	.photo {
		flex: 0 0 auto;
		order: 1;
		margin: -$card-pad 0 $card-pad 0;
		max-height: 20rem;
		overflow: hidden;

		&.square {
			max-height: 37.5rem;
		}

		img {
			width: 100%;
		}

		&.map, &.full-width-video {
			max-height: unset;
			max-width: unset;
		}

		&.map {
			min-height: 12rem;
			@include google-map(12rem);

			&.md {
				min-height: 16rem;
				@include google-map(16rem);
			}

			&.lg {
				min-height: 20rem;
				@include google-map(20rem);
			}

			&.xl {
				min-height: 24rem;
				@include google-map(24rem);
			}
		}
	}

	.title, .category {
		@include font-bold;
		font-size: 1.5rem;
		flex: 0 0 auto;
	}

	.title {
		order: 2;
		margin: 0 $card-pad 0.5rem $card-pad;
		color: var(--extra-dark-text);

		+ .description {
			margin-top: 0.5rem;
		}
	}

	.category {
		order: 3;
		color: var(--light-blue-text);
		margin: 0 $card-pad 1rem $card-pad;
	}

	.description {
		color: var(--extra-dark-text);
		order: 4;
		flex: 9999 0 auto;
		margin: 0 $card-pad 1.25rem $card-pad;

		&, & > p {
			@include font-body;
			font-size: 1.25rem;
		}

		& > p:last-of-type, &.end {
			margin-bottom: 0;
		}
	}

	.action {
		order: 5;
		flex: 1 0 auto;
		margin: 0 $card-pad;
		vertical-align: bottom;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;

		.button, a {
			flex: 0 0 auto;
		}
	}

	&.clamp-lines {
		@include clamp(1);
		@include clamp(3);
		@include clamp(8);
		@include clamp(12);

		.description {
			&, & p {
				display: -webkit-box;
				overflow: hidden;
				line-clamp: var(--card-line-clamp);
				-webkit-line-clamp: var(--card-line-clamp);
				box-orient: vertical;
				-webkit-box-orient: vertical;
			}
		}
	}

	/*@media (prefers-color-scheme: dark) {
		background: var(--background-alt);
	}*/
}
