@import '../_import.scss';

.grid, .grid-section, .row, .row > div {
	background-size: cover;
	background-position: center;
}

.grid {
	display: block;

	.row {
		@include default-padding-sides;
		background: var(--background);

		> .container {
			display: grid;
			grid-template-columns: repeat($grid-col-count, 1fr);
			grid-template-rows: 100%;
			grid-auto-flow: row;
			grid-gap: $grid-row-gap $grid-col-gap;
			justify-items: stretch;
			align-items: stretch;
			justify-content: stretch;
			align-content: stretch;
			max-width: $main-content-width;
		}

		&.min > .container {
			grid-template-rows: min-content;
		}

		&.cards > .container {
			display: block;
			margin: 0 0 1.5rem 0;
		}

		&.striped {
			background: var(--background);
			padding-top: $main-content-padding * 2;
			padding-bottom: $main-content-padding * 2;

			h2 {
				margin-top: 0;
			}

			&:first-of-type {
				padding-top: 0;
			}

			&:nth-of-type(even) {
				background: var(--background-alt);
			}
		}
	}

	.column {
		grid-row: auto / span 1;
	}

	.full {
		@extend .column;
		grid-column: auto / span $grid-col-count;
	}

	.half {
		@extend .column;
		grid-column: auto / span ($grid-col-count / 2);
	}

	.double {
		@extend .column;
		grid-column: auto / span (($grid-col-count / 3) * 2);
	}

	.single {
		@extend .column;
		grid-column: auto / span ($grid-col-count / 3);
	}

	img, .caption {
		width: 100%;
		max-width: 63rem;
	}

	@media (max-width: 75rem) {
		.row {
			display: block;

			.full, .half, .double, .single {
				display: block;
			}
		}
	}
}

.main-container.interior .with-secondary-nav .grid .row {
	padding-left: 0;
	padding-right: 0;
}
