@import '../_import.scss';

.browserupgrade {
	margin: 0;
	padding: 5rem 1rem;
	background: var(--theme-white);
	color: var(--theme-black);
	position: fixed;
	top: 5vh;
	right: 10vh;
	left: 10vh;
	z-index: 5000;
	box-shadow: 0.25rem 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.5), 0 0 0 1000rem rgba(0,0,0, 0.4);
	border: 0.125rem solid rgba(0,0,0, 0.2);

	i, p {
		line-height: 2rem;
		vertical-align: middle !important;
	}

	i {
		color: var(--theme-dark-red);
		vertical-align: middle !important;
		margin: -0.25rem 0.25rem 0 0;
		font-size: 2rem;
	}

	p {
		margin: 0;
		font-size: 1.5rem;
		margin: 0 auto;
		width: 85ch;
		max-width: 80vw;
	}

	strong {
		color: var(--theme-black);
	}

	.close-wrap {
		margin: 1.5rem auto 0 auto;

		.button {
			display: inline-block;
		}
	}
}
