@import '../partials/_colors.scss';

:root, [data-theme="default"] {
	--theme-light-blue: #{$theme-light-blue};
	--theme-dark-blue: #{$theme-dark-blue};
	--theme-light-red: #{$theme-light-red};
	--theme-dark-red: #{$theme-dark-red};
	--theme-light-green: #{$theme-light-green};
	--theme-dark-green: #{$theme-dark-green};
	--theme-extra-light-grey: #{$theme-extra-light-grey};
	--theme-light-grey: #{$theme-light-grey};
	--theme-dark-grey: #{$theme-dark-grey};
	--theme-extra-dark-grey: #{$theme-extra-dark-grey};
	--theme-extra-dark-grey-alt: #{$theme-extra-dark-grey-alt};
	--theme-light-purple: #{$theme-light-purple};
	--theme-dark-purple: #{$theme-dark-purple};
	--theme-light-orange: #{$theme-light-orange};
	--theme-dark-orange: #{$theme-dark-orange};
	--theme-light-slate: #{$theme-light-slate};
	--theme-dark-slate: #{$theme-dark-slate};
	--theme-light-teal: #{$theme-light-teal};
	--theme-dark-teal: #{$theme-dark-teal};
	--theme-light-coral: #{$theme-light-coral};
	--theme-dark-coral: #{$theme-dark-coral};
	--theme-light-byzantine: #{$theme-light-byzantine};
	--theme-dark-byzantine: #{$theme-dark-byzantine};
	--theme-yellow: #{$theme-yellow};
	--theme-black: #{$theme-black};
	--theme-white: #{$theme-white};
	--theme-true-black: #{$theme-true-black};
	//changeable colors
	//text
	--link-text: #{$theme-light-blue};
	--light-text: #{$theme-white};
	--dark-text: #{$theme-black};
	--dark-text-alt: #{lighten($theme-black, 20%)};
	--dark-text-alt2: #{lighten($theme-black, 33%)};
	--extra-dark-text: #{$theme-true-black};
	--blue-text: #{$theme-dark-blue};
	--light-blue-text: #{$theme-light-blue};
	--footer-fill: #{$theme-white};
	// backgrounds
	--background: #{$theme-white};
	--background-alt: #f5f5f5;
	--dark-blue-background: #{$theme-dark-blue};
	--light-blue-background: #{$theme-light-blue};
	// Other Colors
	--text-highlight: #{$theme-yellow};
	--link-visited: #{$link-visited};
	--link-hover: #{$link-hover};
	--secondary-nav-fg: #{$theme-white};
	--secondary-nav-bg: #{$theme-dark-blue};
}
