@import "../_import.scss";

$header-height: 8.75rem;
$logo-width: 9.0625rem;
$right-item-size: 3rem;
$arrow-size: 0.9375rem;
$blue-bar-size: 0.875rem;
$sub-menu-pad: 1.25rem;
$logo-aspect-ratio: 1.8;

$small-header-height: 3.8125rem;
$small-item-height: 1.75rem;
$small-logo-width: 4.3125rem;

body.noscroll {
	overflow: hidden;
}

.header-nav {
	@include default-padding-sides;
	height: $header-height;
	background: var(--theme-true-black);
	color: var(--theme-white);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	z-index: 1100;

	.logo-link {
		flex: 0 0 auto;
		margin-right: 3rem;

		&:focus {
			@include focusDarkBg;
		}
	}

	svg {
		width: $logo-width;
		height: $logo-width / $logo-aspect-ratio;
	}

	.main-nav {
		flex: 0 0 auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
	}

	.right-nav {
		flex: 1 1 auto;
		margin-left: auto;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
	}

	.login-button {
		@include font-bold;
		@include remove-ios-button-styles;
		border: none;
		outline: none;
		color: var(--light-text);
		background: var(--light-blue-background);
		height: $right-item-size;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		border-radius: $right-item-size /2;
		padding: 0 1.5rem;
		font-size: 1.5rem;
		text-decoration: none;
		margin-left: 2.25rem;

		i, img {
			margin-right: 0.875rem;
		}

		i {
			font-size: 1.5rem;
		}

		img {
			border-radius: 0.3125rem;
			width: 1.5rem;
			height: 1.5rem;

			span {
				@include font-bold;
				line-height: 1;
			}
		}

		&:hover {
			box-shadow: inset 0 0 0 1000px rgba(#000, 0.2);
		}

		&:focus {
			@include focusDarkBg;
		}
	}

	.search {

		form {
			display: flex;
			flex-flow: row nowrap;
			align-items: stretch;
			justify-content: stretch;

			&.hide {
				display: none;
			}
		}

		.mobile-search-button, button {
			border: none;
			height: $right-item-size;
			width: $right-item-size;
			padding: 0;
			background: var(--light-blue-background);
			color: var(--light-text);
			text-align: center;
			padding-right: 0.125rem;
			flex: 0 0 auto;

			i {
				line-height: $right-item-size;
			}

			&:hover {
				box-shadow: inset 0 0 0 1000px rgba(#000, 0.2);
			}
		}

		.mobile-search-button {
			border-radius: 50%;
			display: block;

			i {
				margin-left: 0.1875rem;
			}

			&:focus {
				@include focusDarkBg;
			}
		}

		button {
			@include remove-ios-button-styles;
			border-top-right-radius: $right-item-size /2;
			border-bottom-right-radius: $right-item-size / 2;

			&:focus {
				@include focusDarkBg;
			}
		}

		input {
			@include font-body;
			height: $right-item-size;
			padding: 0 1.5rem;
			font-size: 1.5rem;
			flex: 1 1 auto;
			border-top-left-radius: $right-item-size / 2;
			border-bottom-left-radius: $right-item-size / 2;
			border: none;
			outline: none;
			color: var(--dark-text);
			background: var(--background);

			&:focus {
				@include focus(var(--theme-light-blue));
			}
		}

		.hide {
			display: none;
		}
	}
}

.mobile-menu-button {
	display: none;
}

ul.main-nav-list {
	display: flex;
	flex-flow: row nowrap;
	margin: 0;
	padding: 0;

	> li {
		list-style: none;
		margin: 0;
		height: $header-height;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		position: relative;

		+ li {
			margin-left: 0.75rem;
		}
	}

	.top-menu-item {
		@include remove-ios-button-styles;
		padding: 0 1rem;
		@include font-bold;
		color: var(--light-text);
		font-size: 1.625rem;
		text-decoration: none;
		text-transform: uppercase;
		line-height: $right-item-size;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background: none;
		border: none;

		.material-icons {
			transition: color ease-out 0.2s;
		}

		&:hover .material-icons, &.top-menu-item-active .material-icons {
			transition: color ease-in 0.2s;
			color: var(--theme-light-red);
		}

		&:focus {
			@include focusDarkBg;
		}
	}

	.sub-menu {
		position: absolute;
		top: $header-height - ($arrow-size + $blue-bar-size);
		padding-bottom: $arrow-size + $blue-bar-size;
		z-index: 1001;
		overflow: hidden;
		pointer-events: none;

		.wrap {
			position: relative;
			transform: translateY(-115%);
			transition: transform .2s ease-in-out;
		}

		.arrow {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: $arrow-size + $blue-bar-size;

			svg {
				width: 3.25rem;
				height: 1.8125rem;
			}

			.arrow-path {
				fill: var(--light-blue-background);
			}

			&:after {
				content: ' ';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: $blue-bar-size;
				background: var(--light-blue-background);
			}
		}

		ul {
			margin: 0;
			position: relative;
			top: ($blue-bar-size + $arrow-size);
			padding: ($sub-menu-pad) 0 $sub-menu-pad 0;
			display: flex;
			flex-flow: column nowrap;
			background: var(--background);

			li {
				list-style: none;
				padding: 0 0.25rem;
				margin: 0;
				width: auto;
				min-width: 16rem;

				&:hover {
					background: var(--light-blue-background);

					a {
						color: var(--light-text);
					}
				}
			}

			a {
				$am: 0.0625rem;
				@include font-body;
				font-size: 1.125rem;
				display: block;
				width: 100%;
				line-height: 2rem;
				padding: 0 $sub-menu-pad - $am;
				text-decoration: none;
				margin: $am;

				span {
					color: var(--extra-dark-text);
					flex: 1 1 auto;
				}

				&:hover, &.sub-menu-link-active {
					background: var(--light-blue-background);
					color: var(--light-text);

					span {
						color: var(--light-text);
					}
				}

				&:focus {
					@include focus;
				}
			}
		}
	}

	.sub-menu-active {
		pointer-events: unset;
		display: block;
		filter: drop-shadow(0 0 1.5rem var(--theme-true-black));

		.wrap {
			transform: translateY(0);
		}
	}

	.sub-menu-inactive {
		// 	display: none;
	}
}

@media only screen and (max-width : $menu-collapse-search) {
	.header-nav {
		.login-button {
			margin-left: 1rem;
		}
	}
}

@media only screen and (max-width : $menu-shrink) {

	.header-nav {
		height: $small-header-height;

		.logo-link {
			margin-right: 1rem;
			padding-top: 0.125rem;
		}

		svg {
			width: $small-logo-width;
			height: $small-logo-width / $logo-aspect-ratio;
		}

		.login-button {
			line-height: $small-item-height;
			height: $small-item-height;
			font-size: 0.875rem;
			padding: 0 0.875rem;

			img {
				width: 0.875rem;
				height: 0.875rem;
				margin-right: 0.5rem;
				border-radius: 0.25rem;
			}

			&:focus {
				@include focusDarkBg;
			}
		}

		.search .mobile-search-button {
			width: $small-item-height;
			height: $small-item-height;

			.material-icons {
				line-height: $small-item-height;
				font-size: 0.875rem;
				padding-left: 0.125rem;
				margin-left: 0;
			}

			&:focus {
				@include focusDarkBg;
			}
		}
	}

	ul.main-nav-list {
		.top-menu-item {
			font-size: 1.25rem;
			padding: 0 0.5rem;

			.material-icons {
				font-size: 1rem;
			}
		}

		> li {
			height: $small-header-height;
		}

		.sub-menu {
			top: $small-header-height - $arrow-size;
		}
	}
}

@media only screen and (max-width : $menu-collapse-menu) {

	.mobile-menu-button {
		display: block;
		position: absolute;
		left: 1rem;
		top: ($small-header-height - $small-item-height) / 2;
	}

	ul.main-nav-list {
		position: absolute;
		display: block;
		top: $small-header-height;
		transition: top 0.2s ease-in-out;
		left: 0;
		right: 0;
		height: auto;
		max-height: 0;
		overflow-y: auto;
		transition: max-height 0.2s ease-out, box-shadow 0.05s ease-out;

		&, & > li, .sub-menu .wrap {
			transform: none;
			max-width: 100vw;
			width: 100%;
			height: auto;
			display: block;
		}

		& > li, .sub-menu .wrap {
			display: block;
		}

		> li + li {
			margin: 0;
		}

		.top-menu-item {
			background: var(--dark-blue-background);
			color: var(--light-text);
			font-size: 1.125rem;
			line-height: 2.556;
			display: block;
			width: 100%;
			max-width: 100vw;
			text-align: left;
			padding: 0 1.5rem;

			.material-icons {
				display: none;
			}
		}

		.sub-menu {
			position: static;
			top: unset;
			left: unset;
			overflow: visible;
			pointer-events: unset;
			width: 100%;
			padding: 0;

			ul, li, a {
				max-width: 100vw;
			}

			ul {
				top: unset;
				padding: 0;
			}
		}

		> li:hover .sub-menu, .sub-menu-active {
			filter: none;
		}
	}

	.mobile-nav-open ul.main-nav-list {
		max-height: calc(98vh - #{$small-header-height});
		box-shadow: 0 1rem 1.5rem -0.75rem rgba(0, 0, 0, 0.5), 0 30vh 0 25vh rgba(0,0,0, 0.75);
		transition: max-height 0.2s ease-in, box-shadow 0.2s 0.1s ease-in;
	}

	.logo-link {
		margin-left: 3rem;
	}
}
