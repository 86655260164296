@import '../_import.scss';

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	scroll-snap-type: both proximity;
	scroll-behavior: smooth;
}

html, body, main {
	margin: 0;
	padding: 0;
	font-size: $base;
	background: var(--background);
	@include default-font-family;
}

.main-container {
	.interior {
		margin-bottom: 4rem;
	}
}

body {
	min-height: 100vh;
	display: flex;
	flex-flow: column nowrap;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

header, footer, .question {
	flex: 0 0 auto;
}

.skip-nav {
	flex: 1 1 auto;
}

figure {
	width: 100%;
	margin: 0 0 1.5rem 0;
	padding: 0;

	figcaption {
		font-size: 0.8em;
		color: var(--dark-text);

		&.sr {
			@include sr-only;
		}
	}
}

a {
	color: var(--theme-light-blue);
	text-decoration-color: var(--theme-light-blue);

	&:visited {
		color: var(--link-visited);
		text-decoration-color: var(--link-visited);
	}

	&:hover {
		color: var(--theme-light-blue);
		text-decoration-color: var(--theme-dark-blue);
	}

	&:active {
		color: var(--theme-dark-blue);
	}
}
