@import '../_import.scss';

h1, h2, h3, h4, h5, h6, p, blockquote, .text, ul, ol, dl {
	max-width: 72rem;
	color: var(--dark-text);
}

h1, h2, h3, h4, h5, h6 {
	padding: 0;
}

h1, .h1 {
	@include font-body;
	@include default-padding-sides;
	font-size: 3rem; // 48px
	margin: 2rem 0 2.25rem 0;
	max-width: $main-content-width;

	@media screen and (max-width: $menu-collapse-menu) {
		margin: 1rem 0 1.25rem 0;
	}
}

h2, h3 {
	@include font-bold;
}

h2, .h2 {
	font-size: 2rem; // 32px
	margin: 3rem 0 1.5rem 0;
}

h3, .h3 {
	font-size: 1.5rem; // 24px
	margin: 1.5rem 0;
}

h4, h5, h6 {
	@include font-display;
}

h4, .h4 {
	font-size: 1.25rem; // 20px
	margin: 1.25rem 0;
}

h5, .h5 {
	font-size: 1.125rem; // 18px
	margin: 1.125rem 0;
}

h6, .h6 {
	font-size: 1rem;
	margin: 1rem 0;
}

p, blockquote, .text {
	@include font-body;
	line-height: 1.6;
	margin: 0 0 1.5rem 0;
	font-size: 1.125rem;
}

i {
	@include font-body-italic;
}

em {
	@include font-bold-italic;
}

b, strong {
	@include font-bold;

	& em {
		@include font-bold-italic;
	}
}

strong {
	color: var(--theme-dark-red);
	@include font-bold;
	/*@media (prefers-color-scheme: dark) {
		color: var(--light-text);
	}*/
}

ul, ol, dl {
	font-size: 1.125rem;
	margin: 0 0 2rem 0;
	padding: 0;
	list-style: none;
}

ul, ol {
	> li {
		margin: 0 0 0.5rem 1.25rem;

		> p {
			margin: 0;
		}
	}

	ul, ol {
		margin-bottom: 0;
	}
}

ol li::marker {
	color: var(--theme-dark-blue);
}

ol ol {
	counter-reset: nested-list-number;
	margin: 0.5rem 0 0 1rem;

	li {
		counter-increment: nested-list-number;

		&::marker {
			content: counter(nested-list-number, lower-roman) ". ";
		}
	}
}

ul > li {
	list-style: square;
}

ol > li {
	list-style: decimal;
}

video {
	margin-bottom: 2rem;

	.insert {
		float: right;
		width: 50%;
		margin: 0 0 1rem 1.5rem;
	}
}
