@import '../_import.scss';

.two-color-heading {
	font-size: 3.75rem;
	color: var(--extra-dark-text);

	b {
		@include font-bold;
		color: var(--blue-text);
	}

	@media only screen and (max-width: $homepage-smaller-font) {
		font-size: 2rem;
	}
}
