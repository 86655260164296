@import '../_import.scss';

.mobile-menu-button {

	@include remove-ios-button-styles;
	padding: 0 0.125rem;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	border: 0;
	margin: 0;
	background: transparent;
	width: 2rem;
	cursor: pointer;

	&:focus {
		@include focusDarkBg;
	}

	.hamburger-box {
		width: 1.75rem;
		height: 1.75rem;
		display: inline-block;
		position: relative;
	}

	.hamburger-inner {
		transition-duration: 0.22s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		display: block;
		top: 50%;
		margin-top: -0.0625rem;

		&,
		&::before,
		&::after {
			width: 1.75rem;
			height: 0.25rem;
			background-color: var(--light-text);
			border-radius: 0.0625rem;
			position: absolute;
			transition-property: transform;
			transition-duration: 0.15s;
			transition-timing-function: ease;
		}

		&::before,
		&::after {
			content: "";
			display: block;
		}

		&::before {
			top: -.5rem;
			transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
		}

		&::after {
			transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
			bottom: -.5rem;
		}
	}

	&.open {
		.hamburger-inner {
			transform: rotate(225deg);
			transition-delay: 0.12s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

			&::before {
				top: 0;
				opacity: 0;
				transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
			}

			&::after {
				bottom: 0;
				transform: rotate(-90deg);
				transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
			}
		}
	}
}
